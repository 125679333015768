import { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  deleteSSOConfig,
  getAdditionalOrganizations,
  getOrganizationsByIds,
  getSSOConfig,
  updateOrganization,
  updateSSOConfig,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import { Tip } from 'components/Tip';
import { Toggle } from 'components/Toggle';
import { ValidationTextField } from 'components/ValidationTextField';
import { ViewFrame } from 'features/frame/ViewFrame';
import { createFailedOperation } from 'utils/apiResult';
import { downloadFile, uploadTextFile } from 'utils/files';
import { Overwriter } from 'utils/overwriter';
import { getLicenseSelectionOptions } from 'utils/selectorOptions';
import { getSpXml, idpXmlToConfig } from 'utils/ssoConfig';
import { getOperations } from 'utils/operable';
import { getSFOrderItemsOperations } from 'utils/organization';
import { AsyncMultiSelectDropdown } from 'components/AsyncMultiSelectDropdown';
import { APP_URL, SSO_ENTITY_ID } from 'constant';

const tips = {
  upload: 'Upload the Identity Provider (IdP) XML metadata file',
  entityId: 'A unique identifier for SSO entities',
  loginUrl: 'The URL to navigate to for SSO login',
  logoutUrl: 'The URL to navigate to for SSO logout',
  publicCert: 'The X.509 certificate to cryptographically sign SSO requests',
  download: 'Download the Service Provider (SP) XML metadata file',
  replyUrl: 'The URL for the customer to navigate to after SSO login',
  disablePassword: 'If ON, users must log in using SSO and cannot log in using a password',
  autoProvision:
    'If ON, users without a SeekOut account who log in using SSO will have an account created for them',
  autoProvisionLicense: 'The license to apply to auto-provisioned accounts',
  domain: 'Emails with this primary domain will use this SSO config',
  ssoDomain:
    'Emails with this alternate domain will use this SSO config. Some customers have multiple domains.',
  additionalSSODomains:
    'Emails with these additional domains will use this SSO config. Some customers can have additional domains.',
};

const Input = styled('input')({ display: 'none' });
const required: (keyof IssoConfig)[] = [
  'idpEntityId',
  'idpLoginUrl',
  'idpLogoutUrl',
  'idpPublicCer',
];

const getAdditionalOrgOptions = (orgs: IOrganization[]) => {
  return orgs.map((x) => ({
    value: x.id,
    text: `${x.name} (${x.domain})`,
  }));
};

/**
 * The SSO Config view
 */
export const SSOConfig = (): JSX.Element => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // Overwriter state
  const [ssoEdits, setSsoEdits] = useState<Partial<IssoConfig>>({});
  const [ssoValidated, setSsoValidated] = useState<Partial<Record<keyof IssoConfig, boolean>>>({});
  const [orgEdits, setOrgEdits] = useState<Partial<IOrganization>>({});
  const [orgValidated, setOrgValidated] = useState<Partial<Record<keyof IOrganization, boolean>>>(
    {}
  );

  const [operationType, setOperationType] = useState<'update' | 'delete' | 'uploadXml'>();
  const [uploadError, setUploadError] = useState('');
  const [additionalOrgOptions, setAdditionalOrgOptions] = useState<SelectOption[]>([]);

  const derivedOrg = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(derivedOrg, 'ssoConfig');
  const contentOperations = getOperations(derivedOrg, ['ssoConfigAction', 'updateSettings']);
  const licenseOperations = getSFOrderItemsOperations(derivedOrg);
  const { orgId, org, ssoConfig, licenses = [], additionalOrganizations = [] } = derivedOrg;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgId && !viewOperations[0]) {
      dispatch(getSSOConfig(orgId));
    }
  }, [dispatch, orgId, viewOperations]);

  useEffect(() => {
    const getAdditionalOrgsInfo = async () => {
      if (ssoConfig?.additionalOrgIds && ssoConfig?.additionalOrgIds.length > 0) {
        try {
          const response = await getOrganizationsByIds(ssoConfig?.additionalOrgIds);
          setAdditionalOrgOptions(getAdditionalOrgOptions(response));
        } catch (e) {
          console.error(e);
        }
      }
    };

    if (ssoConfig) {
      getAdditionalOrgsInfo();
    }
  }, [ssoConfig]);

  const findOrganizations = (orgInput: string) => {
    if (orgInput.length >= 3) {
      const handler = setTimeout(
        () => dispatch(getAdditionalOrganizations({ orgId, value: orgInput })),
        500
      );
      return () => clearTimeout(handler);
    }
  };

  if (!orgId) return <></>;

  const ssoOverwriter = new Overwriter(ssoEdits, ssoValidated, required, ssoConfig);
  const orgOverwriter = new Overwriter(orgEdits, orgValidated, [], org);

  const setSsoOverwriterState = () => {
    setSsoEdits({ ...ssoOverwriter.edited });
    setSsoValidated({ ...ssoOverwriter.valid });
  };

  const setOrgOverwriterState = () => {
    setOrgEdits({ ...orgOverwriter.edited });
    setOrgValidated({ ...orgOverwriter.valid });
  };

  const clearAllOverwriterState = () => {
    setSsoEdits({});
    setSsoValidated({});
    setOrgEdits({});
    setOrgValidated({});
    setAdditionalOrgOptions(
      additionalOrgOptions.filter((x) => ssoConfig?.additionalOrgIds?.includes(x.value))
    );
  };

  const clearOperation = () => {
    setOperationType(undefined);
    setUploadError('');
  };

  const isSsoEdited = ssoOverwriter.isEdited();
  const isOrgEdited = orgOverwriter.isEdited();

  if (!isSsoEdited || operationType === 'uploadXml') {
    contentOperations.splice(0, 1);
  }
  if (!isOrgEdited || operationType !== 'update') {
    contentOperations.splice(-1, 1);
  }

  if (operationType === 'uploadXml') {
    if (uploadError) {
      contentOperations.push(createFailedOperation(uploadError));
    } else {
      contentOperations.push({ status: 'loading' });
    }
  }

  // Bug Hack Fix: If all operations are successfully complete, get out of operation mode
  if (operationType && contentOperations.every((x) => x?.status === 'succeeded')) {
    setOperationType(undefined);
  }

  const enableReset = isSsoEdited || isOrgEdited;
  const enableSave = enableReset && ssoOverwriter.isValid() && orgOverwriter.isValid();
  const enableDelete = !!ssoConfig;

  const showLicenseSelector = licenseOperations.every((x) => x?.status === 'succeeded');
  const licenseOptions = getLicenseSelectionOptions(licenses, { value: ' ', text: 'Free Trial' });

  let operationDisplayName = '';
  switch (operationType) {
    case 'update':
      operationDisplayName = 'Updating SSO Config';
      break;
    case 'delete':
      operationDisplayName = 'Deleting SSO Config';
      break;
    case 'uploadXml':
      operationDisplayName = 'Uploading Identity Provider Metadata';
      break;
    default:
      break;
  }

  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperationType('uploadXml');
    const xml = await uploadTextFile(e);
    if (xml) {
      try {
        const uploadedConfig = idpXmlToConfig(xml);
        ssoOverwriter.setMany(uploadedConfig);
        setSsoOverwriterState();
        clearOperation();
      } catch (error) {
        if (error instanceof Error) {
          setUploadError(error.message);
        }
      }
    } else {
      // Cancelled / No File
      return clearOperation();
    }
  };

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading SSO Config', viewOperations }}
      header={
        <>
          <Button
            variant="contained"
            disabled={!enableReset}
            onClick={() => clearAllOverwriterState()}
            sx={{ margin: 1 }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            disabled={!enableSave}
            onClick={() => {
              setOperationType('update');

              if (isSsoEdited) {
                dispatch(updateSSOConfig({ orgId, resource: ssoOverwriter.getMerged() }));
              }

              if (isOrgEdited) {
                dispatch(
                  updateOrganization({
                    orgId,
                    resource: { ...orgOverwriter.getMerged(), real: true },
                  })
                );
              }
            }}
            sx={{ margin: 1 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            disabled={!enableDelete}
            onClick={() => setConfirmModalOpen(true)}
            sx={{ margin: 1 }}
          >
            Delete
          </Button>
        </>
      }
      contentLoader={{
        message: operationDisplayName,
        contentOperations,
        forceClose: !operationType,
        onClose: () => clearOperation(),
      }}
    >
      <ColumnContent
        growWidth
        columns={[
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">Customer's Identity Provider Info</Typography>
              <Tip title={tips.upload}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="text/xml"
                    id="contained-button-file"
                    type="file"
                    onChange={uploadHandler}
                  />
                  <IconButton component="span">
                    <FileUploadOutlinedIcon />
                  </IconButton>
                </label>
              </Tip>
            </Box>
            <ValidationTextField
              label="Entity Id"
              tooltip={tips.entityId}
              validationType="string"
              value={ssoOverwriter.get('idpEntityId') || ''}
              onChange={(val, isValid) => {
                ssoOverwriter.set('idpEntityId', val, isValid);
                setSsoOverwriterState();
              }}
            />
            <ValidationTextField
              label="Login Url"
              tooltip={tips.loginUrl}
              validationType="url"
              value={ssoOverwriter.get('idpLoginUrl') || ''}
              onChange={(val, isValid) => {
                ssoOverwriter.set('idpLoginUrl', val, isValid);
                setSsoOverwriterState();
              }}
            />
            <ValidationTextField
              label="Logout Url"
              tooltip={tips.logoutUrl}
              validationType="url"
              value={ssoOverwriter.get('idpLogoutUrl') || ''}
              onChange={(val, isValid) => {
                ssoOverwriter.set('idpLogoutUrl', val, isValid);
                setSsoOverwriterState();
              }}
            />
            <ValidationTextField
              label="Public Certificate"
              tooltip={tips.publicCert}
              validationType="base64"
              value={ssoOverwriter.get('idpPublicCer') || ''}
              onChange={(val, isValid) => {
                ssoOverwriter.set('idpPublicCer', val, isValid);
                setSsoOverwriterState();
              }}
              multiline
            />
          </>,

          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">SeekOut's Service Provider Info</Typography>
              <Tip title={tips.download}>
                <IconButton
                  onClick={() => downloadFile('Seekout-SP-Metadata', getSpXml(orgId), 'text/xml')}
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tip>
            </Box>
            <Tip title={tips.entityId}>
              <TextField label="Entity Id" value={SSO_ENTITY_ID} disabled sx={{ marginTop: 2 }} />
            </Tip>
            <Tip title={tips.replyUrl}>
              <TextField
                label="Reply Url"
                value={`${APP_URL}api/auth/sso/${orgId}`}
                disabled
                sx={{ marginTop: 2 }}
              />
            </Tip>

            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Organization Settings
            </Typography>
            <Toggle
              label="Disable Password"
              value={ssoOverwriter.get('onlyAllowSSOLogins') || false}
              onChange={(value) => {
                ssoOverwriter.set('onlyAllowSSOLogins', value, true);
                setSsoOverwriterState();
              }}
              tooltip={tips.disablePassword}
              tipModel="SsoConfig"
              tipProperty="onlyAllowSSOLogins"
            />
            <Toggle
              label="Auto-Provision Accounts"
              value={ssoOverwriter.get('allowLoginCreation') || false}
              onChange={(value) => {
                ssoOverwriter.set('allowLoginCreation', value, true);
                setSsoOverwriterState();
              }}
              tooltip={tips.autoProvision}
              tipModel="SsoConfig"
              tipProperty="allowLoginCreation"
            />
            {ssoOverwriter.get('allowLoginCreation') &&
              (showLicenseSelector ? (
                <Selector
                  label="Auto-Provision License"
                  options={licenseOptions}
                  value={ssoOverwriter.get('defaultSku') || ' '}
                  setValue={(val) => {
                    ssoOverwriter.set('defaultSku', val as string, true);
                    setSsoOverwriterState();
                  }}
                />
              ) : (
                <Tip title={tips.autoProvisionLicense}>
                  <TextField
                    label="Auto-Provision License"
                    value={ssoOverwriter.get('defaultSku') || ''}
                    onChange={(e) => {
                      ssoOverwriter.set('defaultSku', e.target.value, true);
                      setSsoOverwriterState();
                    }}
                    sx={{ marginTop: 2 }}
                  />
                </Tip>
              ))}
            <AsyncMultiSelectDropdown
              placeholder="Type organization name or domain"
              label="Additional Organizations"
              noOptionsText="No organizations"
              suggestions={getAdditionalOrgOptions(additionalOrganizations)}
              onChange={(value) => {
                setAdditionalOrgOptions(value);
                ssoOverwriter.set(
                  'additionalOrgIds',
                  value.map((x) => x.value),
                  true
                );
                setSsoOverwriterState();
              }}
              onInputChange={(value) => findOrganizations(value)}
              value={additionalOrgOptions}
              sx={{ marginTop: 2 }}
            />

            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Known Email Addresses
            </Typography>
            <Tip title={tips.domain}>
              <TextField
                label="Primary Domain"
                value={org?.domain}
                disabled
                sx={{ margin: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">example@</InputAdornment>,
                }}
              />
            </Tip>
            <ValidationTextField
              label="Alternate SSO Domain 1"
              tooltip={tips.ssoDomain}
              validationType="domain"
              value={orgOverwriter.get('ssoDomain1') || ''}
              prefix="example@"
              onChange={(val, isValid) => {
                orgOverwriter.set('ssoDomain1', val, isValid || !val);
                setOrgOverwriterState();
              }}
            />
            <ValidationTextField
              label="Alternate SSO Domain 2"
              tooltip={tips.ssoDomain}
              validationType="domain"
              value={orgOverwriter.get('ssoDomain2') || ''}
              prefix="example@"
              onChange={(val, isValid) => {
                orgOverwriter.set('ssoDomain2', val, isValid || !val);
                setOrgOverwriterState();
              }}
            />
            <ValidationTextField
              label="Additional SSO Domains"
              tooltip={tips.additionalSSODomains}
              validationType="domains"
              value={orgOverwriter.get('additionalSSODomains')?.join(',') || ''}
              prefix="example@"
              multiline
              onChange={(val, isValid) => {
                const domains = val.trim().length > 0 ? val.split(',').map((x) => x.trim()) : [];
                orgOverwriter.set('additionalSSODomains', domains, isValid || !val);
                setOrgOverwriterState();
              }}
            />
          </>,
        ]}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt="Delete SSO Config?"
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          setOperationType('delete');
          clearAllOverwriterState();
          dispatch(deleteSSOConfig(orgId));
        }}
      />
    </ViewFrame>
  );
};
