import { APP_URL, SSO_ENTITY_ID } from 'constant';
import { isBase64, isGuid, isUrl } from './convert';

/*
 * SAML libraries (passport-saml, samlify)
 * - Are intended for the actual passing of SAML messages
 * - Are built for for Node.js backends and contain dependencies that won't work client-side
 *
 * Our use case is different
 * - We only need to parse the XML metadata and perform basic validation
 * - We need to do this client-side
 * - Due to using react-script@5, it is difficult to shim the Node.js objects
 *   - https://github.com/facebook/create-react-app/issues/11756
 *   - https://github.com/facebook/create-react-app/pull/11764
 *
 * As a result
 * - We do our own limited parsing and validation instead of relying on a library
 * - We use samlify (https://github.com/tngan/samlify) as a guide
 */

/**
 * Convert an Identity Provider (IdP) XML string to partial SSO config object
 * @param xml The IdP XML string
 * @returns A partial SSO config containing the properties parsed from the xml
 * @throws On parsing error or when a required property is absent/malformed
 */
export function idpXmlToConfig(xml: string): Partial<IssoConfig> {
  // Parse the XML into a DOM tree
  const xmlAsDom = new DOMParser().parseFromString(xml, 'text/xml');

  // On error, the parser adds a <parsererror> block with divs for each error
  const parserErrors = xmlAsDom.querySelector('parsererror')?.getElementsByTagName('div');

  if (parserErrors?.length) {
    const errorTexts = Array.from(parserErrors).map((x) => x.textContent);
    throw new Error(`Could not parse XML file: ["${errorTexts.join('", "')}"]`);
  }

  // Get the three properties we actually care about
  const entityId = xmlAsDom.querySelector('EntityDescriptor')?.getAttribute('entityID');
  const url = xmlAsDom.querySelector('SingleSignOnService')?.getAttribute('Location');
  const cert = xmlAsDom.querySelector('X509Certificate')?.textContent;

  if (!entityId) throw new Error('EntityId attribute is missing from EntityDescriptor element');
  if (!url) throw new Error('Location attribute is missing from SingleSignOnService element');
  if (!cert) throw new Error('X509Certificate element is missing or empty');

  // The cert may be in a standard display format, but we need normalized Base64
  // Code taken from samlify as the equivalent of saml.Utility.normalizeCerString(cert.trim());
  // https://github.com/tngan/samlify/blob/1bf1eba492ab0249e49282eac16bfa1451e3c4a6/src/utility.ts#L127
  const certB64 = cert
    .trim()
    .replace(/\n/g, '')
    .replace(/\r/g, '')
    .replace(`-----BEGIN CERTIFICATE-----`, '')
    .replace(`-----END CERTIFICATE-----`, '')
    .replace(/ /g, '');

  /*
   * Simple Validation
   * - url MUST be a URL
   * - normalizedCert MUST be a Base64 string
   *
   * No extra validation for entityId
   * - entityId MUST be a URL according to spec
   * - In practice it is often given any presumably unique string
   *
   * Relevant schema definitions
   * - http://docs.oasis-open.org/security/saml/v2.0/saml-metadata-2.0-os.pdf
   * - https://www.w3.org/TR/xmldsig-core
   */

  if (!isUrl(url)) throw new Error(`Location attribute must be a URL, but is "${url}"`);
  if (!isBase64(certB64)) {
    const trimmed =
      certB64.length > 55 ? `it starts "${certB64.slice(0, 50)}..."` : `it is "${certB64}"`;
    throw new Error(`X509Certificate must be Base64 encoded, ${trimmed}`);
  }

  return {
    idpEntityId: entityId,
    idpLoginUrl: url,
    idpLogoutUrl: url,
    idpPublicCer: certB64,
  };
}

/**
 * Get the Service Provider (SP) XML string for an organization
 * @param orgId The organization id
 * @returns The SP XML string
 */
export function getSpXml(orgId: string) {
  if (!isGuid(orgId)) return '';

  const entityId = SSO_ENTITY_ID;
  const location = `${APP_URL}api/auth/sso/${orgId}`;

  // Text as generated by https://www.samltool.com/sp_metadata.php
  return `<?xml version="1.0"?>
<EntityDescriptor entityID="${entityId}" xmlns="urn:oasis:names:tc:SAML:2.0:metadata" xmlns:assertion="urn:oasis:names:tc:SAML:2.0:assertion" xmlns:ds="http://www.w3.org/2000/09/xmldsig#">
  <SPSSODescriptor AuthnRequestsSigned="false" WantAssertionsSigned="false" protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
    <NameIDFormat>urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress</NameIDFormat>
    <AssertionConsumerService index="0" Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="${location}">
    </AssertionConsumerService>
  </SPSSODescriptor>
</EntityDescriptor>`;
}
